import { BigNumber, Signer, Contract, constants, ContractFactory } from 'ethers';
import { Provider, TransactionResponse } from '@ethersproject/providers';
import { arrayify, EventFragment, FunctionFragment, Interface, isHexString, verifyMessage } from 'ethers/lib/utils';
import * as OnchainID from '@onchain-id/solidity';

import { ClaimData, ClaimScheme, ClaimTopic } from '../claim/Claim.interface';
import { Key, KeyPurpose, KeyType } from './Key.interface';
import { IdentityInterface } from './identity.interface';
import { normalizeAddress, resolveENS } from '../core/utils/ENS';
import { Claim } from '../claim/Claim';
import { encodeAndHash } from '../core/utils/Utils';
import { InvalidProviderError, OperationForbiddenError } from '../core/errors/Errors';
import { BlockchainOptions } from '../core/utils/blockchain-options';

export class KeyPurposeAlreadyRegisteredError extends Error {
  public constructor({ message = 'Key already has purpose on identity contract.' }: { message?: string } = {}) {
    super(message);
    this.name = 'KeyPurposeAlreadyRegisteredError';

    Object.setPrototypeOf(this, KeyPurposeAlreadyRegisteredError.prototype);
  }
}

export class KeyPurposeNotRegisteredError extends Error {
  public constructor({ message = 'Key does not have the purpose on identity contract.' }: { message?: string } = {}) {
    super(message);
    this.name = 'KeyPurposeNotRegisteredError';

    Object.setPrototypeOf(this, KeyPurposeNotRegisteredError.prototype);
  }
}

export class InvalidKeyError extends Error {
  public constructor({ message = 'Definition of the Key is not valid.' }: { message?: string } = {}) {
    super(message);
    this.name = 'InvalidKeyError';

    Object.setPrototypeOf(this, InvalidKeyError.prototype);
  }
}

export class InvalidClaimError extends Error {
  public constructor({ message = 'Definition of the Claim is not valid.' }: { message?: string } = {}) {
    super(message);
    this.name = 'InvalidClaimError';

    Object.setPrototypeOf(this, InvalidClaimError.prototype);
  }
}

export class NonExistingClaimError extends Error {
  public constructor({ message = 'There is no such claim.' }: { message?: string } = {}) {
    super(message);
    this.name = 'NonExistingClaimError';

    Object.setPrototypeOf(this, NonExistingClaimError.prototype);
  }
}

export class Identity implements IdentityInterface {
  public keyHolderInstance?: Contract;
  public claimHolderInstance?: Contract;
  public address?: string;
  public provider?: Provider | Signer;

  private deploymentContract?: Contract;

  /**
   * Instantiate a new Identity with the provided address or ENS string that will be resolved.
   * @param addressOrENS Must be a valid Ethereum address, checksumed, all lower-case or all uppercase.
   * @param options
   * @params options.provider If provided, the identity will use this provider for all blockchain operation (unless override) instead of the SDK default provider.
   */
  public static async at(addressOrENS: string, options?: BlockchainOptions): Promise<Identity> {
    let address: string;

    if (!addressOrENS.includes('.')) {
      address = normalizeAddress(addressOrENS);
    } else {
      if (Provider.isProvider(options?.provider)) {
        address = await resolveENS(addressOrENS, options?.provider as Provider);
      } else if (Signer.isSigner(options?.signer) && Provider.isProvider(options?.signer.provider)) {
        address = await resolveENS(addressOrENS, options?.signer.provider as Provider);
      } else {
        throw new InvalidProviderError('Resolving ENS requires a Provider.');
      }
    }

    return new Identity(address, options?.signer || options?.provider);
  }

  /**
   * Deploy a new Identity, and return the Identity object.
   * The signer will pay for the deployment, and will be added in the MANAGEMENT keys.
   * If not given, the Signer will use the default provider from the SDK if it is defined and is a Signer.
   * Note that the identity will be returned with the provided Signer, thus management operation can be chained.
   * @param config - Configuration of the identity to deploy.
   * @param config.managementKey - Ethereum address to set as the initial management key.
   * @param config.implementationAuthority - Ethereum address of the implementation authority to use.
   * @param options
   * @param [options.factory] address of the factory to deploy a proxy identity.
   * @example Usually called with `identity.deployed()`:
   * ```typescript
   * const identity = await Identity.deployNew();
   * await identity.deployed();
   * ```
   */
  public static async deployNew(config: { managementKey: string; implementationAuthority: string; }, options: BlockchainOptions): Promise<Identity> {
    if (!Signer.isSigner(options.signer)) {
      throw new InvalidProviderError('Contract deployment requires a Signer.');
    }

    if(!isHexString(config.managementKey)) {
      throw new InvalidKeyError();
    }

    const contract = await new ContractFactory(OnchainID.contracts.IdentityProxy.abi, OnchainID.contracts.IdentityProxy.bytecode, options?.signer).deploy(
      config.implementationAuthority,
      config.managementKey,
      options?.overrides ?? {},
    );

    const identity = new Identity(contract.address, options?.signer);
    identity.deploymentContract = contract;
    return identity;
  }

  /**
   * Instantiate an Identity.
   * @param address A valid Ethereum address (not an ENS, use `Identity#at(ens)`.).
   * @param provider Override the default provider of SDK, and use for all operation of this Identity.
   */
  public constructor(address: string, provider?: Provider | Signer) {
    this.address = normalizeAddress(address);
    this.claimHolderInstance = undefined;
    this.keyHolderInstance = undefined;
    this.provider = provider;
  }

  /**
   * Add a claim to an Identity.
   * The signature must have been signed with a keypair having the public key in the CLAIM keys of Identity.
   * @param topic
   * @param scheme
   * @param issuer
   * @param signature
   * @param data
   * @param uri
   * @param [options]
   */
  public async addClaim(topic: ClaimTopic, scheme: ClaimScheme, issuer: string, signature: string, data: string, uri: string, options?: BlockchainOptions): Promise<TransactionResponse> {
    const _signer = options?.signer || this.provider;
    if (!Signer.isSigner(_signer)) {
      throw new InvalidProviderError('Contract operations require a Signer.');
    }

    if (!isHexString(signature)) {
      throw new InvalidClaimError({ message: 'signature must be a valid hex string.' });
    }
    const signatureBytes = arrayify(signature);
    if (data.length > 0 && !isHexString(data)) {
      throw new InvalidClaimError({ message: 'data must be a valid hex string.' });
    }
    const dataBytes = arrayify(data.length > 0 ? data : '0x');

    let instance = this.claimHolderInstance;
    if (!instance || options?.signer) {
      instance = await this.instantiateClaimHolder(_signer);
    }

    if (!await this.keyHasPurpose(encodeAndHash(['address'], [await _signer.getAddress()]), KeyPurpose.CLAIM, options)) {
      throw new OperationForbiddenError({ message: 'CLAIM key required on the identity to add a claim.' });
    }

    return instance.addClaim(topic, scheme, issuer, signatureBytes, dataBytes, uri, options?.overrides ?? {});
  }

  /**
   * Add a Key to an Identity.
   * The Signer must have a MANAGEMENT key in the Identity.
   * @param key Must be a valid byte32 hex string (pass the keccak256 hash of the key string encoded (abi.encode)).
   * @param purpose Must be an integer. It is recommended to use the standard KeyPurpose enum.
   * @param type Must be a an integer. It is recommended to use the standard KeyType enum.
   * @param [options]
   */
  public async addKey(key: string, purpose: KeyPurpose, type: KeyType, options?: BlockchainOptions): Promise<TransactionResponse> {
    const _signer = options?.signer || this.provider ;
    if (!Signer.isSigner(_signer)) {
      throw new InvalidProviderError('Contract operations require a Signer.');
    }

    let instance = this.keyHolderInstance;
    if (!instance || options?.signer) {
      instance = await this.instantiateKeyHolder(_signer);
    }

    if (!await this.keyHasPurpose(encodeAndHash(['address'], [await _signer.getAddress()]), KeyPurpose.MANAGEMENT, options)) {
      throw new OperationForbiddenError({ message: 'MANAGEMENT key required on the identity to add a key.' });
    }

    if(!isHexString(key)) {
      throw new InvalidKeyError();
    }

    if (await this.keyHasPurpose(key, purpose, options)) {
      throw new KeyPurposeAlreadyRegisteredError();
    }

    return instance.addKey(key, purpose, type, options?.overrides ?? {});
  }

  /**
   * Returns the Identity if the Identity was deployed, or awaits for the Identity to be deployed before returning it.
   *
   * @example Usually called after a `Identity.deployNew()`:
   * ```typescript
   * const identity = await Identity.deployNew();
   * await identity.deployed();
   * ```
   */
  public async deployed(): Promise<Identity> {
    if (this.deploymentContract) {
      await this.deploymentContract.deployed();

      return this;
    }

    return this;
  }

  /**
   * Get ClaimData details for an Identity.
   * @param claimId
   * @param [options]
   */
  public async getClaim(claimId: string, options?: BlockchainOptions): Promise<ClaimData> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.claimHolderInstance;
    if (!instance) {
      instance = await this.instantiateClaimHolder(_provider);
    }

    return instance.getClaim(claimId).then((claim: any[]) => {
      if (claim[2] === constants.AddressZero) {
        return null;
      }

      return new Claim({
        address: this.address,
        id: claimId,
        topic: claim[0].toNumber(),
        scheme: claim[1].toNumber(),
        issuer: claim[2],
        signature: claim[3],
        data: claim[4],
        uri: claim[5],
      });
    });
  }

  /**
   * Get claims details for an Identity.
   * @deprecated
   * @param claimId
   * @param [options]
   */
  public async getClaims(claimId: string, options?: BlockchainOptions): Promise<ClaimData[]> {
    throw new Error('Claim retrieval must be performed by exploring ClaimAdded and ClaimRemoved events.');
  }

  /**
   * Get Claims details by topic for an Identity.
   * @param topic
   * @param [options]
   */
  public async getClaimsByTopic(topic: ClaimTopic | number, options?: BlockchainOptions): Promise<ClaimData[]> {
    const promises = await this.getClaimIdsByTopic(topic, options)
      .then((claimIds: string[]): Promise<ClaimData>[] =>
        claimIds.map(async (claimId: string): Promise<ClaimData> =>
          this.getClaim(claimId, options)
        ),
      );

    return Promise.all<ClaimData>(promises);
  }

  /**
   * Get ClaimData IDs by topic for an Identity.
   * @param topic
   * @param [options]
   */
  public async getClaimIdsByTopic(topic: ClaimTopic | number, options?: BlockchainOptions): Promise<string[]> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.claimHolderInstance;
    if (!instance) {
      instance = await this.instantiateClaimHolder(_provider);
    }

    return instance.getClaimIdsByTopic(topic);
  }

  /**
   * Returns the deployment transaction of the Identity if it was previously created with Identity.deployNew().
   *
   * @example Can be called only after a `Identity.deployNew()`:
   * ```typescript
   * const identity = await Identity.deployNew();
   * identity.getDeployTransaction();
   * ```
   */
  public getDeployTransaction(): TransactionResponse | null {
    if (this.deploymentContract) {
      return this.deploymentContract.deployTransaction;
    }

    return null;
  }

  /**
   * Get the details of a key in an Identity.
   * @param key
   * @param [options]
   */
  public async getKey(key: string, options?: BlockchainOptions): Promise<Key> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.keyHolderInstance;
    if (!instance) {
      instance = await this.instantiateKeyHolder(_provider);
    }

    return instance.getKey(key).then((key: [BigNumber[], BigNumber, string]) => {
      if (key[2] === '0x0000000000000000000000000000000000000000000000000000000000000000') {
        return null;
      }

      return {
        purposes: key[0].map(purpose => purpose.toNumber()),
        type: key[1].toNumber(),
        key: key[2],
      };
    });
  }

  /**
   * Get the purpose of a key in an identity.
   * @param key
   * @param [options]
   */
  public async getKeyPurposes(key: string, options?: BlockchainOptions): Promise<KeyPurpose[]> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.keyHolderInstance;
    if (!instance) {
      instance = await this.instantiateKeyHolder(_provider);
    }

    return instance.getKeyPurposes(key).then((purposes: BigNumber[]) => {
      return purposes.map((purpose: BigNumber) => purpose.toNumber());
    });
  }

  /**
   * Get the details of the keys contained in an Identity by purpose.
   * @param purpose
   * @param [options]
   */
  public async getKeysByPurpose(purpose: KeyPurpose, options?: BlockchainOptions): Promise<Key[]> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.keyHolderInstance;
    if (!instance) {
      instance = await this.instantiateKeyHolder(_provider);
    }

    const promises = await instance.getKeysByPurpose(purpose)
      .then((keys: string[]): Promise<Key>[] => {
        return keys.map(async (key: string): Promise<Key> => {
          return this.getKey(key, options);
        });
      });

    return Promise.all<Key>(promises);
  }

  /**
   * Instantiate the Identity IdentityInterface Contract with the Identity's address.
   * @param [providerOrSigner]
   */
  public async instantiateClaimHolder(providerOrSigner?: Provider | Signer): Promise<Contract> {
    const _provider = providerOrSigner ?? this.provider;

    if (this.claimHolderInstance && (this.claimHolderInstance.provider === _provider || this.claimHolderInstance.signer === _provider)) {
      return this.claimHolderInstance;
    }

    this.claimHolderInstance = await this.instantiate(OnchainID.interfaces.IERC735.abi, _provider);

    return this.claimHolderInstance;
  }

  /**
   * Instantiate the Identity KeyHolder Contract with the Identity's address.
   * @param [providerOrSigner]
   */
  public async instantiateKeyHolder(providerOrSigner?: Provider | Signer): Promise<Contract> {
    const _provider = providerOrSigner ?? this.provider;

    if (this.keyHolderInstance && (this.keyHolderInstance.provider === _provider || this.keyHolderInstance.signer === _provider)) {
      return this.keyHolderInstance;
    }

    this.keyHolderInstance = await this.instantiate(OnchainID.interfaces.IERC734.abi, _provider);

    return this.keyHolderInstance;
  }

  /**
   * Instantiate an Identity with the given abi using the object's address.
   * @param abi
   * @param [providerOrSigner]
   */
  public async instantiate(abi: Array<string | FunctionFragment | EventFragment> | string | Interface, providerOrSigner?: Provider | Signer): Promise<Contract> {
    if (!this.address) {
      throw new Error('Identity has no address defined. Use .instantiateAtAddress() or set .address first.');
    }

    const _provider = providerOrSigner ?? this.provider;

    return this.instantiateAtAddress(this.address, abi, _provider);
  }

  /**
   * Instantiate an Identity with the given abi at a given address.
   * @param address
   * @param abi
   * @param [providerOrSigner]
   */
  public async instantiateAtAddress(address: string, abi: Array<string | FunctionFragment | EventFragment> | string | Interface, providerOrSigner?: Provider | Signer): Promise<Contract> {
    const _providerOrSigner = providerOrSigner ?? this.provider;
    if (!Provider.isProvider(_providerOrSigner) && !Signer.isSigner(_providerOrSigner)) {
      throw new InvalidProviderError('A provider or a signer is required to instanciate a contract.');
    }

    return new Contract(address, abi, _providerOrSigner);
  }

  /**
   * Check if a key has at least the given purpose.
   * @param key
   * @param purpose
   * @param [options]
   */
  public async keyHasPurpose(key: string, purpose: KeyPurpose, options?: BlockchainOptions): Promise<boolean> {
    const _provider = options?.provider ?? options?.signer ?? this.provider;

    let instance = this.keyHolderInstance;
    if (!instance) {
      instance = await this.instantiateKeyHolder(_provider);
    }

    return instance.keyHasPurpose(key, purpose);
  }

  /**
   * Remove a claim, provided the signer has the right to do so.
   * @param claimId
   * @param [options]
   */
  public async removeClaim(claimId: string, options?: BlockchainOptions): Promise<TransactionResponse> {
    const _signer = options?.signer ?? this.provider;
    if (!Signer.isSigner(_signer)) {
      throw new InvalidProviderError('Contract operations require a Signer.');
    }

    if (!await this.keyHasPurpose(encodeAndHash(['address'], [await _signer.getAddress()]), KeyPurpose.CLAIM, options)) {
      throw new OperationForbiddenError({ message: 'CLAIM key required on the identity to remove a claim.' });
    }

    const claim = await this.getClaim(claimId, options);
    if (!claim || claim.topic === 0) {
      throw new NonExistingClaimError({ message: 'The specified claim ID was not found on the Identity.' });
    }

    let instance = this.claimHolderInstance;
    if (!instance || options?.signer) {
      instance = await this.instantiateClaimHolder(_signer);
    }

    return instance.removeClaim(claimId, options?.overrides ?? {});
  }

  /**
   * Remove a Key from an Identity.
   * The Signer must have a MANAGEMENT key in the Identity.
   * @param key Key must be a valid byte32 hex string.
   * @param purpose KeyPurpose must be a valid byte32 hex string.
   * @param [options]
   */
  public async removeKey(key: string, purpose: number, options?: BlockchainOptions): Promise<TransactionResponse> {
    const _signer = options?.signer ?? this.provider;
    if (!Signer.isSigner(_signer)) {
      throw new InvalidProviderError('Contract operations require a Signer.');
    }

    if(!isHexString(key)) {
      throw new InvalidKeyError();
    }

    let instance = this.keyHolderInstance;
    if (!instance) {
      instance = await this.instantiateKeyHolder(_signer);
    }

    if (!await this.keyHasPurpose(encodeAndHash(['address'], [await _signer.getAddress()]), KeyPurpose.MANAGEMENT, options)) {
      throw new OperationForbiddenError({ message: 'MANAGEMENT key required on the identity to remove a key.' });
    }

    if(!(await this.keyHasPurpose(key, purpose, options))) {
      throw new KeyPurposeNotRegisteredError();
    }

    return instance.removeKey(key, purpose, options?.overrides ?? {});
  }

  /**
   * Use another provider or signer to interact with the Identity.
   * This will reset all contract instances of the identity that will need to be instantiated once again with the new provider.
   * @param providerOrSigner
   */
  public useProvider(providerOrSigner: Provider | Signer): void {
    this.provider = providerOrSigner;

    // Reset contract instances.
    this.claimHolderInstance = undefined;
    this.keyHolderInstance = undefined;
  }

  /**
   * Verify if the message was signed with a key that is authorized to perform action for this Identity.
   * @param message
   * @param signature
   * @param [options]
   */
  public async validateSignature(message: string, signature: string, options?: BlockchainOptions): Promise<boolean> {
    let signingKey: string;
    try {
      signingKey = verifyMessage(message, signature);
    } catch (err) {
      return false;
    }

    return await this.keyHasPurpose(encodeAndHash(['address'], [signingKey]), KeyPurpose.ACTION, options);
  }

  /**
   * Verify a specific claim given with full data or by ID.
   * @param claim Claim object or ID.
   * @param [options]
   */
  public async verifyClaim(
    claim: any | string,
    options?: BlockchainOptions,
  ): Promise<{ valid: boolean; reason?: string }> {
    if (!this.address) {
      return {
        valid: false,
        reason: 'Identity is not deployed.',
      };
    }

    const _provider = options?.provider ?? options?.signer ?? this.provider;
    if (!Provider.isProvider(_provider)) {
      throw new Error('Provider is required to verify claim validity.');
    }

    let claimData: any;
    if (typeof claim === 'string') {
      claimData = await this.getClaim(claim, options);
    } else {
      claimData = claim;
    }

    const _claim = new Claim(claimData);

    const valid = await _claim.verifyValidity(_provider);

    return {
      valid,
    };
  }
}
