export enum KeyPurpose {
  MANAGEMENT = 1,
  ACTION = 2,
  CLAIM = 3,
}

export enum KeyType {
  ECDSA = 1,
  RSA = 2,
}

export interface Key {
  purposes: KeyPurpose[];
  type: KeyType;
  key: string;
}
