import { Provider } from '@ethersproject/providers';
import { arrayify, sha256, toUtf8Bytes, verifyMessage } from 'ethers/lib/utils';
import * as OnchainID from '@onchain-id/solidity';

import {
  ClaimData,
  ClaimObject,
  ClaimStatus,
  ClaimTopic
} from './Claim.interface';
import { SignerModule, SignerModuleInterface } from '../core/SignerModule';
import { Contract } from 'ethers';
import { encodeAndHash, HexString, isHexString } from '../core/utils/Utils';
import { InvalidProviderError } from '../core/errors/Errors';

export class Claim implements ClaimObject {
  public address?: string;
  public data?: string;
  public hash?: string;
  public id?: string;
  public issuanceDate?: Date;
  public emissionDate?: Date;
  public issuer?: string;
  public privateData?: object;
  public publicData?: object;
  public scheme?: number;
  public signature?: string;
  public status?: ClaimStatus;
  public topic?: number;
  public uri?: string;

  /**
     * Generate the hash of a claim using the provided data, use this method when using a JSON scheme with public and private data.
     * This hash should be put in the `data` field of the claim.
     * @param topic
     * @param emissionDate
     * @param publicData
     * @param privateData
     */
  public static generateHash(topic: ClaimTopic, emissionDate: Date, publicData: object, privateData: object): string {
    if (publicData && privateData && emissionDate && topic) {
      return sha256(toUtf8Bytes(JSON.stringify({ topic, emissionDate, privateData, publicData })));
    } else {
      throw new Error("Can't generate the Claim Hash because some data is missing.");
    }
  }

  /**
   * Generate the blockchain hash of the claim. This is the hash that has to be sign by the claim issuer.
   * @param address
   * @param topic
   * @param data
   */
  public static generateBlockchainHash(address: string, topic: number, data: string): string {
    return encodeAndHash(['address', 'uint256', 'bytes'], [address, topic, data]);
  }

  public static generateClaimID(issuer: string, topic: ClaimTopic): string {
    return encodeAndHash(['address', 'uint256'], [issuer, topic]);
  }

  /**
   * Generate the blockchain hash of a claim and signs it with the provided signer or the default signer of the SDK.
   * @param topic
   * @param address
   * @param data
   * @param [signer]
   * @returns Claim signature.
   */
  public static async sign(topic: ClaimTopic, address: string, data: HexString, signer: SignerModuleInterface): Promise<string> {
    if (!SignerModule.isSignerModule(signer)) {
      throw new InvalidProviderError('A signer is required to generate a claim signature.');
    }

    if (!isHexString(data)) {
      throw new Error('Claim data to sign must be a valid hex string.');
    }

    const claimHash = Claim.generateBlockchainHash(address, topic, data);
    return signer.signMessage(arrayify(claimHash));
  }

  /**
     * Verify the signature of a claim.
     * The standard signature of a claim is the keccak256 hash of (identityAddress, topic, data) prefixed and signed.
     * The data argument is exactly the content of the data claim field stored in blockchain (caution to hex padding).
     * Data is expected to be an hexString.
     * Using the IdentitySDK, call `IdentitySDK.utils.toHex('data')`.
     * Using web3utils, call `web3utils.asciiToHex('data')`.
     * Using ethersjs, call `Ethers.utils.hexlify(Ethers.utils.toUtf8Bytes('data'))`
     * @param signingKey
     * @param topic
     * @param address Address of identity contract.
     * @param data
     * @param signature
     */
  public static async verifySignature(signingKey: string, topic: ClaimTopic, address: string, data: string, signature: string): Promise<boolean> {
    if (!isHexString(data)) {
      throw new Error('Claim data to sign must be a valid hex string.');
    }
    const claimHash = Claim.generateBlockchainHash(address, topic, data);

    try {
      const signerAddress = await verifyMessage(arrayify(claimHash), signature);

      return signerAddress === signingKey;
    } catch (err) {
      return false;
    }
  }

  /**
     * Create a new Claim Object from a ClaimData (got from BlockChain Identity Contract).
     * Use #.createFromURI() to fetch from an URI.
     * @param claim
     */
  public constructor(claim?: ClaimData) {
    if (claim) {
      Object.assign(this, claim);
    }
  }

  /**
     * Generate the hash of the claim data if it was populated with private data.
     * If all data are not provided as arguments, data fromm the Claim object will be used.
     * Note that to verify a claim complete data, you will need to have access to its private data.
     * Use the .populate() method to fetch all the public and private data if available.
     * @param topic
     * @param emissionDate
     * @param publicData
     * @param privateData
     */
  public generateHash(topic?: ClaimTopic, emissionDate?: Date, publicData?: object, privateData?: object): string {
    if (publicData && privateData && emissionDate && topic) {
      return Claim.generateHash(topic, emissionDate, publicData, privateData);
    } else {
      if (!this.publicData || !this.privateData || !this.emissionDate) {
        throw new Error("Can't generate the Claim Hash because some data is missing. Call .populate() first to retrieve data from the Claim Issuer.");
      }
      return this.generateHash(this.topic, this.emissionDate, this.publicData, this.privateData);
    }
  }

  /**
   * Sign the claim.
   * It will update the signature property of the claim. The signature can only be generated for a claim that as a topic, an address and data.
   * @param signer Signer module to use. If null, use default signer of SDK.
   */
  public async sign(signer: SignerModuleInterface): Promise<string> {
    if (!this.topic) {
      throw new Error('Claim has no topic defined, thus it cannot be signed.');
    }

    if (!this.address) {
      throw new Error('Claim has no address defined, thus it cannot be signed.');
    }

    if (!this.data) {
      throw new Error('Claim has no data defined, thus it cannot be signed.');
    }

    const signature = await Claim.sign(this.topic, this.address, this.data, signer);

    this.signature = signature;

    return signature;
  }

  /**
   * Verify the validity of a claim against the Claim Issuer Contract.
   * @param provider Provider instance to fetch blockchain data.
   * @return true if the claim is declared valid by the Claim Issuer, false otherwise.
   */
  public async verifyValidity(provider: Provider): Promise<boolean> {
    if (!this.topic || !this.address || !this.signature) {
      throw new Error('Claim is not complete and thus cannot be verified.');
    }
    if (!this.issuer) {
      throw new Error('A claim that has no issuer address cannot be verified.');
    }

    const claimIssuerInstance = new Contract(this.issuer, OnchainID.interfaces.IClaimIssuer.abi, provider);

    return claimIssuerInstance.isClaimValid(this.address, this.topic, this.signature, this.data);
  }
}
