import { SignerModuleInterface } from "../core/SignerModule";

export enum ClaimTopic {
  KYC = 1,
}

export enum ClaimScheme {
  SOME = 1,
}

export enum ClaimStatus {
  EXPECTED = 'EXPECTED', // Can receive access grants, but has no data yet.
  READY = 'READY', // Claim has been generated, but not issued to BlockChain yet.
  PENDING = 'PENDING', // Claim has been issued issued to BlockChain but transaction is awaiting confirmation.
  CONFIRMED = 'CONFIRMED', // Claim issued to BlockChain.
  REVOKED = 'REVOKED', // Claim has been revoked.
}

export interface ClaimData {
  address?: string;
  data?: string;
  hash?: string;
  emissionDate?: Date;
  id?: string;
  issuanceDate?: Date;
  issuer?: string;
  publicData?: object;
  privateData?: object;
  scheme?: number;
  signature?: string;
  status?: ClaimStatus;
  topic?: number;
  uri?: string;
}

export interface ClaimObject extends ClaimData {
  generateHash(topic?: ClaimTopic, issuanceDate?: Date, publicData?: object, privateData?: object): string;
  sign(signer?: SignerModuleInterface): Promise<string>;
}
