import { ClaimScheme, ClaimTopic } from './claim/Claim.interface';
import { KeyPurpose, KeyType } from './identity/Key.interface';
import { interfaces as solidityInterfaces, contracts as solidityContracts } from '@onchain-id/solidity';
import * as crypto from './crypto-module';

const enums = {
  ClaimScheme,
  ClaimType: ClaimTopic,
  KeyPurpose,
  KeyType,
};

const contracts = {
  ERC734: solidityInterfaces.IERC734,
  ERC735: solidityInterfaces.IERC735,
  Identity: solidityContracts.Identity,
};

export { crypto, enums, contracts };
export * from './core/utils/Utils';



